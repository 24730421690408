<template>
  <div class="tile is-ancestor is-parent"
    style="height: 87vh; overflow-y: auto;">
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div class="columns">
          <div class="column">
            <p class="title">
              <span class="has-badge-child-offset2">Images</span>
              <span v-if="imageCount"
                class="has-badge-rounded has-badge-info has-badge-large"
                :data-badge="imageCount" />
            </p>
            <p class="subtitle">{{ isDropzoneActive ? 'Upload images' : 'Manage quote images' }}</p>
          </div>
          <div class="column is-narrow">
            <p class="buttons">
              <a v-show="isClearAllActive"
                class="button is-primary"
                @click="dropzoneRemoveAllFiles">
                <span class="icon">
                  <i class="mdi mdi-close mdi-18px" />
                </span>
                <span>Clear All</span>
              </a>
              <a v-show="!isDropzoneActive"
                class="button tooltip"
                @click="getThumbnails"
                data-tooltip="Refresh"
                :disabled="isLoadingImages">
                <span class="icon">
                  <i class="mdi mdi-refresh mdi-24px" />
                </span>
              </a>
              <a v-show="!isDropzoneActive"
                class="button tooltip"
                @click="setSelectedStatusAll(true)"
                data-tooltip="Select all"
                :disabled="isLoadingImages">
                <span class="icon has-text-success">
                  <i class="mdi mdi-select-all mdi-24px" />
                </span>
              </a>
              <a v-show="!isDropzoneActive && hasSelected"
                class="button tooltip"
                @click="setSelectedStatusAll(false)"
                data-tooltip="Deselect all">
                <span class="icon has-text-grey">
                  <i class="mdi mdi-select-off mdi-24px" />
                </span>
              </a>
              <a v-show="!isDropzoneActive && hasSelected"
                class="button tooltip"
                @click="showCopyImageDialog"
                data-tooltip="Copy to quote">
                <span class="icon has-text-primary">
                  <i class="mdi mdi-content-copy mdi-24px" />
                </span>
              </a>
              <a v-show="!isDropzoneActive && hasSelected"
                class="button tooltip"
                @click="showDeletAllModal"
                data-tooltip="Delete selected">
                <span class="icon has-text-danger">
                  <i class="mdi mdi-delete-sweep mdi-24px" />
                </span>
              </a>
              <a v-show="!isDropzoneActive && hasSelected"
                class="button tooltip"
                @click="showDownloadImagesModal"
                data-tooltip="Download selected">
                <span class="icon has-text-primary">
                  <i class="mdi mdi-download mdi-24px" />
                </span>
              </a>
              <a class="button is-primary"
                @click="toggleDropzone"
                :disabled="(isDropzoneActive && isUploading) || isLoadingImages">
                <span class="icon">
                  <i class="mdi mdi-18px"
                    :class="[ isDropzoneActive ? 'mdi-minus' : 'mdi-plus' ]" />
                </span>
                <span>{{ buttonText }}</span>
              </a>
            </p>
          </div>
        </div>
        <div class="field">
          <vue-dropzone v-show="isDropzoneActive"
            ref="quoteImageDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            @vdropzone-mounted="dropzoneMounted"
            @vdropzone-file-added="dropzoneFileAdded"
            @vdropzone-success="dropzoneSuccess"
            @vdropzone-error-multiple="dropzoneErrorMultiple"
            @vdropzone-sending="dropzoneSending"
            @vdropzone-total-upload-progress="dropzoneTotalUploadProgress"
            @vdropzone-complete-multiple="dropzoneCompleteMultiple"
            @vdropzone-queue-complete="dropzoneQueueComplete" />
        </div>
        <div v-show="!isDropzoneActive && isLoadingImages"
          class="columns is-multiline">
          <div class="section">
            <span class="is-loading-image has-text-grey has-text-centered" />
          </div>
        </div>
        <div v-show="!isDropzoneActive"
          class="">
          <draggable v-model="imagesWithInfo"
            v-bind="{chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
            @change="onImageOrderChange"
            class="columns is-multiline"
            handle=".item-drag-handle">
            <div v-for="(image, index) in imagesWithInfo"
              :key="image.quoteImageId"
              class="column is-narrow p-2">
              <div v-if="image.imageContent"
                :class="'card'"
                style="box-shadow: 0 0 0 1px #d9d9d9"
                @dblclick="makeImagePrimary(image)">
                <header class="card-header">
                  <!-- <p class="card-header-title">
                  Image title
                </p> -->
                  <!-- <p class="card-header-title"
                  style="justify-content: flex-end"> -->
                  <div class="card-header-title p-2 has-background-grey-lighter">
                    <div class="columns is-gapless"
                      style="width: 100%">
                      <div class="column">
                        <div class="field">
                          <div class="control">
                            <div class="pretty p-icon p-curve p-smooth">
                              <input :id="`chk-pretty-image1${index}`"
                                :name="`chk-pretty-image${index}`"
                                v-model="image.isSelected"
                                type="checkbox">
                              <div class="state p-success">
                                <i class="icon mdi mdi-check" />
                                <label />
                              </div>
                            </div>
                            <!-- <input class="is-checkradio is-small is-success"
                            :class="{ 'has-background-color': image.isSelected }"
                            :id="`chk-image${index}`"
                            :name="`chk-image${index}`"
                            type="checkbox"
                            v-model="image.isSelected">
                          <label :for="`chk-image${index}`"
                            data-label-empty /> -->
                          </div>
                        </div>
                      </div>
                      <div class="column is-narrow">
                        <a class="has-text-primary is-outlined"
                          @click="editImage(image.quoteImageId)">
                          <span class="icon">
                            <i class="mdi mdi-lead-pencil mdi-24px"
                              aria-hidden="true" />
                          </span>
                        </a>
                        <a class="has-text-danger is-outlined"
                          @click="showDeleteModal(image.quoteImageId)">
                          <span class="icon">
                            <i class="mdi mdi-delete mdi-24px"
                              aria-hidden="true" />
                          </span>
                        </a>
                        <a class="has-text-dark is-outlined item-drag-handle">
                          <span class="icon">
                            <i class="mdi mdi-drag-vertical mdi-24px"
                              aria-hidden="true" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </header>
                <div class="card-image">
                  <a @click="viewImage(index)">
                    <figure class="image is-128x128">
                      <div style="position: relative">
                        <i v-if="image.isPrimary"
                          class="mdi mdi-star mdi-24px has-text-gold"
                          style="position: absolute; z-index: 1" />
                        <img v-if="image.imageContent"
                          class="image is-128x128"
                          style="object-fit: cover;"
                          :src="image.imageContent"
                          :key="image.quoteImageId"
                          :id="image.quoteImageId">
                      </div>
                    </figure>
                  </a>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </article>
    </div>
    <confirm-modal :active.sync="isConfirmModalActive"
      @ok="deleteImage(true)"
      @cancel="deleteImage(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete image</p>
      <p slot="text-content">
        Image will be deleted. Continue?
      </p>
    </confirm-modal>
    <confirm-modal :active.sync="isDeleteAllModalActive"
      @ok="deleteSelectedImages(true)"
      @cancel="deleteSelectedImages(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete images</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ selectedCount }}</span> selected images will be deleted. Continue?
      </p>
    </confirm-modal>
    <confirm-modal :active.sync="isDownloadImagesModalActive"
      @ok="downloadSelectedImages(true)"
      @cancel="downloadSelectedImages(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Download images</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ selectedCount }}</span> selected images will be downloaded. Continue?
      </p>
    </confirm-modal>
    <quote-no-search-modal v-if="isCopyImageModalActive"
      :active.sync="isCopyImageModalActive"
      @copy="copyImagesToQuote"
      @close="closeCopyImageModal" />
    <bulma-carousel-modal v-if="isCarouselModalActive"
      :image-width="800"
      :image-height="600"
      :active.sync="isCarouselModalActive"
      :image-ids="imageIds"
      :start-index="carouselStartIndex"
      @close="closeCarousel()" />
  </div>
</template>

<script>
import QuoteService from '../QuoteService'
import { QuoteNoSearchModal } from '../components'
import { OpenImage } from '@/components/VuePainterro'
import { ConfirmModal } from '@/components/BulmaModal'
import VueDropzone from '@/components/VueDropzone'
import CommonApiService from '@/services/common'
import _isEmpty from 'lodash/isEmpty'
import mimeTypes from 'mime-types'
import { BulmaCarouselModal } from '@/components/BulmaCarousel'
import _debounce from 'lodash/debounce'
import { EventHubTypes } from '@/enums'
import draggable from 'vuedraggable'

export default {
  name: 'QuoteImagesOld',
  components: {
    ConfirmModal,
    VueDropzone,
    QuoteNoSearchModal,
    BulmaCarouselModal,
    draggable
  },
  props: {
    value: null
  },
  data() {
    return {
      size: 200,
      images: [],
      imageIds: null,
      imagesSelected: null,
      imagesWithInfo: [],
      isConfirmModalActive: false,
      isDeleteAllModalActive: false,
      isCopyImageModalActive: false,
      isDownloadImagesModalActive: false,
      selectedImageId: '',
      queuedFileCount: 0,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_WEBAPI_URI}/quotes/images/upload/${this.value.quoteId}/${this.value.prefix}${this.value.quoteNo}${this.value.suffix}/${this.value.subQuoteNo}`,
        dictDefaultMessage: 'Drop files here or click to upload',
        thumbnailWidth: 128,
        thumbnailHeight: 128,
        maxFilesize: 10,
        resizeWidth: 800,
        acceptedFiles: 'image/*',
        capture: 'image/',
        headers: {
          Authorization: ''
        },
        accept: function (file, done) {
          const mimeType = file.type
          if (mimeType !== mimeTypes.contentType('jpeg')) {
            this.options.resizeMimeType = mimeTypes.contentType('jpeg')
            this.options.resizeQuality = 0.9
            done()
            return
          }
          done()
        },
        renameFile(file) {
          const extension = file.name.split('.').pop()
          if (extension !== 'jpg' || extension !== 'jpeg') {
            return `${file.name.substr(0, file.name.lastIndexOf('.'))}.${mimeTypes.extension(mimeTypes.contentType('jpeg'))}`
            // return file.name.substr(0, file.name.lastIndexOf('.')) + '.jpg'
          } else {
            return file.name
          }
        }
      },
      isDropzoneActive: false,
      isClearAllActive: false,
      isUploading: false,
      isCarouselModalActive: false,
      carouselStartIndex: 0,
      isLoadingImages: false,
      lastScrollX: 0,
      lastScrollY: 0,
      areThumbnailsLoaded: false
    }
  },
  computed: {
    buttonText() {
      return this.isDropzoneActive ? 'Close' : 'Add'
    },
    hasSelected() {
      return this.imagesWithInfo.some((i) => i.isSelected)
    },
    selectedCount() {
      return this.imagesWithInfo.reduce(function (total, item) {
        return item.isSelected ? total + 1 : total
      }, 0)
    },
    imageCount() {
      return this.imageIds ? this.imageIds.length : 0
    }
  },
  created() {
    this.dropzoneOptions.headers.Authorization = `Bearer ${CommonApiService.getAuthToken()}`
    this.getThumbnails()
  },
  methods: {
    makeImagePrimary(targetImage) {
      for (var image of this.imagesWithInfo) {
        image.isPrimary = false
      }
      targetImage.isPrimary = true
      QuoteService.setQuoteImageIsPrimary(targetImage.quoteImageId)
    },
    onImageOrderChange(event, originalEvent) {
      const imageIds = this.imagesWithInfo.map((i) => i.quoteImageId)
      QuoteService.setQuoteImagesOrderIndex(this.value.quoteId, imageIds)
    },
    async getThumbnail(id, index) {
      var thumbnail = await QuoteService.getImageThumbnail(id, this.size)
      this.images.splice(index, 1, thumbnail)
    },
    async getThumbnailWithInfo(id, index) {
      var thumbnail = await QuoteService.getImageThumbnailWithInfo(id, this.size)
      return thumbnail
    },
    async getThumbnails() {
      this.isLoadingImages = true
      this.imagesWithInfo.splice(0, this.imagesWithInfo.length)
      const vm = this
      this.imageIds = await QuoteService.getImageIds(this.value.quoteId)
      // this.images = new Array(this.imageIds.length)
      // this.initSelectedIds(this.imageIds.length)
      const promises = this.imageIds.map(function (id, index) {
        // vm.getThumbnail(id, index)
        return vm.getThumbnailWithInfo(id, index)
      })
      this.imagesWithInfo = await Promise.all(promises)
      this.$eventHub.$emit(EventHubTypes.ImageCountChanged, this.imageIds.length)
      this.isLoadingImages = false
      this.areThumbnailsLoaded = true
    },
    // initSelectedIds(length) {
    //   this.imagesSelected = new Array(length)
    //   let size = length
    //   while (size--) {
    //     this.imagesSelected.splice(size, 1, false)
    //   }
    // },
    setSelectedStatusAll(selected) {
      // let size = this.imagesSelected.length
      // while (size--) {
      //   this.imagesSelected.splice(size, 1, selected)
      // }
      this.imagesWithInfo.forEach((i) => (i.isSelected = selected))
    },
    toggleDropzone() {
      if (this.isDropzoneActive && this.isUploading) {
        return
      }

      this.isDropzoneActive = !this.isDropzoneActive
      if (!this.isDropzoneActive) {
        this.dropzoneRemoveAllFiles()
        // if (_isEmpty(this.images)) {
        //   this.getThumbnails()
        // }
        if (_isEmpty(this.imagesWithInfo)) {
          this.getThumbnails()
        }
      }
    },
    saved1: _debounce(async function (id) {
      // Callback function to update thumbnail after saving images in Painterro
      const thumbnail = this.imagesWithInfo.find((i) => i.quoteImageId === id)
      const updatedThumbnail = await this.getThumbnailWithInfo(id, this.size)
      thumbnail.imageContent = updatedThumbnail.imageContent
      // Force image source update
      // document.getElementById(thumbnail.quoteImageId).src = thumbnail.imageContent
    }, 300),
    async saved(id) {
      // Callback function to update thumbnail after saving images in Painterro
      const thumbnail = this.imagesWithInfo.find((i) => i.quoteImageId === id)
      const updatedThumbnail = await this.getThumbnailWithInfo(id, this.size)
      thumbnail.imageContent = updatedThumbnail.imageContent
      // Force image source update
      // document.getElementById(thumbnail.quoteImageId).src = thumbnail.imageContent
      this.$eventHub.$emit(`${EventHubTypes.ImageCountChanged}UpdateQuote`, this.imageIds.length)
    },
    closed() {
      window.scroll(this.lastScrollX, this.lastScrollY)
    },
    editImage(id) {
      this.$showSpinner()
      this.lastScrollX = window.scrollX
      this.lastScrollY = window.scrollY
      window.scrollTo(0, 0)
      OpenImage(id, this.saved, this.closed, false)
      this.$hideSpinner()
    },
    async viewImage(index) {
      this.$showSpinner()
      this.carouselStartIndex = index
      this.isCarouselModalActive = true
      this.$hideSpinner()
    },
    closeCarousel() {
      this.isCarouselModalActive = false
    },
    showDeleteModal(id) {
      this.selectedImageId = id
      this.isConfirmModalActive = true
    },
    showDeletAllModal() {
      this.isDeleteAllModalActive = true
    },
    showDownloadImagesModal() {
      this.isDownloadImagesModalActive = true
    },
    async pause(msec) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, msec || 1000)
      })
    },
    async downloadSelectedImages(confirm) {
      this.isDownloadImagesModalActive = false
      if (confirm) {
        const imageIds = this.imagesWithInfo.filter((i) => i.isSelected).map((i) => i.quoteImageId)
        let promises = []
        for (let i = 0; i < imageIds.length; i++) {
          const id = imageIds[i]
          promises.push(this.downloadImage(id))
          if (promises.length === 10) {
            await Promise.all(promises)
            promises = []
            await this.pause(1000)
          }
        }
      }
    },
    async downloadImage(id) {
      const result = await QuoteService.downloadImage(id)
      const blob = new Blob([result.data], { type: result.headers['content-type'] })
      const disposition = result.headers['content-disposition']
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      let filename = ''
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
      const link = document.createElement('a')
      link.style = 'display: none'
      const url = window.URL.createObjectURL(blob)
      link.href = url
      link.download = filename
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      delete link.href
    },
    deleteImage(confirm) {
      this.isConfirmModalActive = false
      if (confirm) {
        QuoteService.deleteImage(this.selectedImageId)
        const index = this.imageIds.indexOf(this.selectedImageId)
        // this.imagesSelected.splice(index, 1)
        // this.images.splice(index, 1)
        this.imageIds.splice(index, 1)
        this.imagesWithInfo.splice(index, 1)
        this.$eventHub.$emit(EventHubTypes.ImageCountChanged, this.imageIds.length)
        this.$eventHub.$emit(`${EventHubTypes.ImageCountChanged}UpdateQuote`, this.imageIds.length)
      }
      this.selectedImageId = ''
    },
    async deleteSelectedImages(confirm) {
      this.isDeleteAllModalActive = false
      if (confirm) {
        this.$showSpinner('Removing images')
        const count = this.selectedCount
        // const vm = this
        // const deletedImages = this.imagesWithInfo.filter(i => i.isSelected)
        // const promises = deletedImages.map(function(image, index) {
        //   if (image.isSelected) {
        //     QuoteService.deleteImage(image.quoteImageId)
        //   }
        // })
        // await Promise.all(promises)
        const deletedImageIds = this.imagesWithInfo.filter((i) => i.isSelected).map((i) => i.quoteImageId)
        await QuoteService.deleteMultipleImages(deletedImageIds)
        this.imagesWithInfo = this.imagesWithInfo.filter((i) => !i.isSelected)
        this.imageIds = this.imagesWithInfo.filter((i) => !i.isSelected).map((i) => i.quoteImageId)
        this.$hideSpinner()
        this.$eventHub.$emit(EventHubTypes.ImageCountChanged, this.imageIds.length)
        this.$eventHub.$emit(`${EventHubTypes.ImageCountChanged}UpdateQuote`, this.imageIds.length)
        this.$notification.openNotificationWithType('success', 'Delete images', `${count} images deleted`)
      }
    },
    showCopyImageDialog() {
      this.isCopyImageModalActive = true
      // this.copyImagesToQuote('09725f31-3f36-45f8-a1e2-446ff7e6fb92')
    },
    closeCopyImageModal() {
      this.isCopyImageModalActive = false
    },
    async copyImagesToQuote(quoteId) {
      this.isCopyImageModalActive = false
      this.$showSpinner('Copying images')
      // const vm = this
      // const selectedIds = this.imageIds.filter(function(id, index) {
      //   if (vm.imagesSelected[index]) {
      //     return id
      //   }
      // })
      const selectedIds = this.imagesWithInfo.filter((i) => i.isSelected).map((i) => i.quoteImageId)
      await QuoteService.copyImagesToQuote(quoteId, selectedIds)
      this.$notification.openNotificationWithType('success', 'Copy images', `${this.selectedCount} images copied`)
      this.$hideSpinner()
    },
    dropzoneMounted() {
      // console.log(this.$refs.quoteImageDropzone.getAcceptedFiles())
    },
    dropzoneFileAdded() {
      this.isClearAllActive = true
      this.isUploading = true
    },
    dropzoneSuccess(file, response) {
      // console.log(file)
      this.queuedFileCount++
      // this.images = []
      this.imagesWithInfo = []
    },
    dropzoneErrorMultiple(files, message, xhr) {},
    dropzoneSending(file, xhr, formData) {
      // console.log(file)
      // for (var value of formData.values()) {
      //   console.log(value)
      // }
    },
    dropzoneCompleteMultiple(files) {
      console.log(files.count)
    },
    async dropzoneQueueComplete(files, xhr, formData) {
      this.isUploading = false
      this.$notification.openNotificationWithType('success', 'Upload images', `${this.queuedFileCount} images uploaded`)
      this.queuedFileCount = 0
      this.imageIds = await QuoteService.getImageIds(this.value.quoteId)
      this.$eventHub.$emit(EventHubTypes.ImageCountChanged, this.imageIds.length)
      this.$eventHub.$emit(`${EventHubTypes.ImageCountChanged}UpdateQuote`, this.imageIds.length)
    },
    dropzoneTotalUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) {
      // console.log(totaluploadprogress)
    },
    dropzoneRemoveAllFiles() {
      this.$refs.quoteImageDropzone.removeAllFiles()
      this.isClearAllActive = false
    }
  }
}
</script>
<style>
</style>
